import React from 'react';

function Dolci() {
  const dolci = [
    {
      nome: 'Tiramisu',
      descrizione: '(Löffelbiskuits, Kaffee, Sahne, Mascarpone)',
      prezzoQui: 6.90,
      prezzoAsporto: 4.90,
    },
    {
      nome: 'Casata Siziliana',
      descrizione: '()',
      prezzoQui: 8.80,
      prezzoAsporto: 6.80,
    },
    {
      nome: 'Tartufo-Eis',
      descrizione: '(Vanilleeis, Schokoladeneis, Kakaopulver, Gehackte Haselnüsse)',
      prezzoQui: 8.80,
      prezzoAsporto: 6.80,
    },
    {
      nome: 'Zitronensorbet',
      descrizione: '(Eis mit Zitronengeschmack)',
      prezzoQui: 8.80,
      prezzoAsporto: 6.80,
    },
    {
      nome: 'Kokos-Eis',
      descrizione: '(Eis mit Kokosgeschmack)',
      prezzoQui: 8.80,
      prezzoAsporto: 6.80,
    },
    {
      nome: 'Kuchen',
      descrizione: '(Des Tages)',
      prezzoQui: 8.00,
      prezzoAsporto: 6.00,
    },
  ];

  const renderDish = (dish, index) => (
    <React.Fragment key={index}>
      {index > 0 && <hr className="my-4 border-[#F4E1C1] opacity-30" />}
      <div className="p-3 bg-[#8B4513] rounded-lg text-[#F4E1C1]">
        <h4 className="text-lg font-medium">{dish.nome}</h4>
        <p className="text-sm italic mb-2">{dish.descrizione}</p>
        <div className="flex flex-col mt-2 space-y-2">
          <div className="flex justify-between">
            <span>Hier essen:</span>
            <span className="font-bold">{dish.prezzoQui.toFixed(2)} €</span>
          </div>
          {dish.prezzoAsporto !== undefined && (
            <div className="flex justify-between">
              <span>Zum Mitnehmen:</span>
              <span className="font-bold">{dish.prezzoAsporto.toFixed(2)} €</span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Unsere Desserts</h3>
      {dolci.map(renderDish)}
    </div>
  );
}

export default Dolci;
