import React from 'react';
import caffeImage from './images/caffe.jpg';
import grappaImage from './images/grappa.jpg';

function Feinkost() {
  const emporio = [
    {
      nome: 'Kaffe Moka Dor',
      descrizione: '1 kg',
      prezzoQui: 18.0,
      prezzoAsporto: 0.0,
      immagine: caffeImage,
    },
    {
      nome: 'In der Aufbauphase',
      descrizione: 'In der Aufbauphase',
      prezzoQui: 0.0,
      prezzoAsporto: 0.0,
      immagine: grappaImage,
    },
  ];

  const renderEmporioItem = (item, index) => (
    <div
      key={index}
      className="flex flex-col sm:flex-row mb-6 bg-[#A0522D] rounded-lg overflow-hidden"
    >
      <div className="w-full sm:w-1/3 flex-shrink-0 card-3d-image">
        <img
          src={item.immagine}
          alt={item.nome}
          className="w-full h-full object-cover card-img-top"
        />
      </div>
      <div className="w-full sm:w-2/3 p-4">
        <h4 className="text-xl font-medium text-[#F4E1C1]">{item.nome}</h4>
        <p className="text-sm italic mb-2 text-[#F4E1C1]">{item.descrizione}</p>
        <div className="mt-2">
          <p className="text-[#F4E1C1]">
            Preis: <span className="font-bold">{item.prezzoQui.toFixed(2)} €</span>
          </p>
          {item.prezzoAsporto !== item.prezzoQui && (
            <p className="text-[#F4E1C1]">
              Zum Mitnehmen:{' '}
              <span className="font-bold">{item.prezzoAsporto.toFixed(2)} €</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-[#F4E1C1]">Unsere Feinkost</h3>
      {emporio.map(renderEmporioItem)}
    </div>
  );
}

export default Feinkost;