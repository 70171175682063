import React from 'react';
import { createRoot } from 'react-dom/client';
import './App.css';
import App from './App';


const renderApp = () => {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } else {

    const newContainer = document.createElement('div');
    newContainer.id = 'root';
    document.body.appendChild(newContainer);
    const root = createRoot(newContainer);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', renderApp);
} else {
  renderApp();
}
