import React from 'react';

function Vorspeisen() {
  const antipasti = [
    {
      nome: 'Antipasto vegegi',
      descrizione: '(Gegrilltes Gemüse)',
      prezzoQui: 15.20,
      prezzoAsporto: 12.20,
    },
    {
      nome: 'Tagliere',
      descrizione: '(Gegrilltes Gemüse, Verschiedene Käsesorten, Verschiedene Wurstwaren)',
      prezzoQui: 22.90,
      prezzoAsporto: 19.90,
    },
    {
      nome: 'Tagliere Für vier Personen',
      descrizione: '(Gegrilltes Gemüse, Verschiedene Käsesorten, Verschiedene Wurstwaren)',
      prezzoQui: 43.50,
      prezzoAsporto: 38.00,
    },
    {
      nome: 'Rindercarpaccio',
      descrizione: '(Rinderfleisch, Rucola, Parmesan)',
      prezzoQui: 16.50,
      prezzoAsporto: 13.50,
    },
    {
      nome: 'Lachscarpaccio',
      descrizione: '(Lachs, Rucola)',
      prezzoQui: 16.50,
      prezzoAsporto: 13.50,
    },
    {
      nome: 'Gemischter Salat',
      descrizione: '(Salat, Paprika, Gurke, Karotten, Tomate, Rucola)',
      prezzoQui: 8.20,
      prezzoAsporto: 5.20,
    },
    {
      nome: 'Tomatensalat',
      descrizione: '(Tomate, Zwiebel, Basilikum)',
      prezzoQui: 7.20,
      prezzoAsporto: 4.20,
    },
    {
      nome: 'Rucola Salat',
      descrizione: '(Rucola, Kirschtomaten, Parmesan)',
      prezzoQui: 11.10,
      prezzoAsporto: 8.10,
    },
    {
      nome: 'Pastore Salat',
      descrizione: '(Gemischter Salat, Parmaschinken, Fetakäse, Oliven, Peperoni)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Nizzarda Salat',
      descrizione: '(Gemischter Salat, Thunfisch, Zwiebel, Oliven, Peperoni, Ei)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Paranza Salat',
      descrizione: '(Gemischter Salat, Bresaola, Mozzarella, Artischocken, Mais)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Käsetteler',
      descrizione: '(Gemischte Käsesorten)',
      prezzoQui: 15.30,
      prezzoAsporto: 12.30,
    },
  ];

  const renderDish = (dish, index) => (
    <React.Fragment key={index}>
      {index > 0 && <hr className="my-4 border-[#F4E1C1] opacity-30" />}
      <div className="p-3 bg-[#8B4513] rounded-lg text-[#F4E1C1]">
        <h4 className="text-lg font-medium">{dish.nome}</h4>
        <p className="text-sm italic mb-2">{dish.descrizione}</p>
        <div className="flex flex-col mt-2 space-y-2">
          <div className="flex justify-between">
            <span>Hier essen:</span>
            <span className="font-bold">{dish.prezzoQui.toFixed(2)} €</span>
          </div>
          {dish.prezzoAsporto !== undefined && (
            <div className="flex justify-between">
              <span>Zum Mitnehmen:</span>
              <span className="font-bold">{dish.prezzoAsporto.toFixed(2)} €</span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Unsere Vorspeisen</h3>
      {antipasti.map(renderDish)}
    </div>
  );
}

export default Vorspeisen;
