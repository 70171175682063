import React from 'react';

function Vino() {
  const vini = [
    {
      nome: 'Trebbiano (Herkunft Abruzzen)',
      descrizione: '(Halbtrocken)',
      prezzoBicchiereQui: 6.50,
      prezzoBottigliaQui: 19.00,
      prezzoBottigliaAsporto: 10.00,
    },
    {
      nome: 'Pinot Grigio (Herkunft Friaul)',
      descrizione: '(Halbtrocken)',
      prezzoBicchiereQui: 8.00,
      prezzoBottigliaQui: 28.00,
      prezzoBottigliaAsporto: 14.90,
    },
    {
      nome: 'Zibibbo (Herkunft Sizilien)',
      descrizione: '(Halbtrocken, Fruchtig)',
      prezzoBicchiereQui: 8.00,
      prezzoBottigliaQui: 28.00,
      prezzoBottigliaAsporto: 14.90,
    },
    {
      nome: 'Montepulciano (Herkunft Abruzzen)',
      descrizione: '(Trocken)',
      prezzoBicchiereQui: 6.50,
      prezzoBottigliaQui: 19.00,
      prezzoBottigliaAsporto: 10.00,
    },
    {
      nome: 'Rotwein N 6 Cuvée (Herkunft Piemont)',
      descrizione: '(Halbtrocken, Fruchtig, Vol 14%)',
      prezzoBicchiereQui: 9.00,
      prezzoBottigliaQui: 28.00,
      prezzoBottigliaAsporto: 19.50,
    },
    {
      nome: 'Lambrusco (Herkunft Reggio Emilia)',
      descrizione: '(Perlend, Halbtrocken, Fruchtig, Vol 11%)',
      prezzoBicchiereQui: 8.00,
      prezzoBottigliaQui: 28.00,
      prezzoBottigliaAsporto: 19.50,
    },
  ];

  const renderWine = (wine, index) => (
    <React.Fragment key={index}>
      {index > 0 && <hr className="my-4 border-[#F4E1C1] opacity-30" />}
      <div className="p-3 bg-[#8B4513] rounded-lg text-[#F4E1C1]">
        <h4 className="text-lg font-medium">{wine.nome}</h4>
        <p className="text-sm italic mb-2">{wine.descrizione}</p>
        <div className="flex flex-col mt-2 space-y-2">
          <div className="flex justify-between">
            <span>Glas (Hier trinken):</span>
            <span className="font-bold">{wine.prezzoBicchiereQui.toFixed(2)} €</span>
          </div>
          <div className="flex justify-between">
            <span>Flasche (Hier trinken):</span>
            <span className="font-bold">{wine.prezzoBottigliaQui.toFixed(2)} €</span>
          </div>
          <div className="flex justify-between">
            <span>Flasche (Zum Mitnehmen):</span>
            <span className="font-bold">{wine.prezzoBottigliaAsporto.toFixed(2)} €</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Unsere Weine</h3>
      {vini.map(renderWine)}
    </div>
  );
}

export default Vino;
