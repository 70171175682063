import React, { useState, useRef } from 'react';
import * as LucideIcons from 'lucide-react';
import image from './image.jpg';

import Pasta from './componens/Pasta';
import Tageskarte from './componens/Tageskarte';
import Vorspeisen from './componens/Vorspeisen';
import Dolci from './componens/Dolci';
import Eventi from './componens/Eventi';
import Feinkost from './componens/Feinkost';
import Vino from './componens/Vino';
import Pizza from './componens/Pizza';
import Aperitifs from './componens/Aperitifs'; 

function App() {
  const [activeSection, setActiveSection] = useState('home');
  const contentRef = useRef(null); 
  const headerRef = useRef(null);  

  const menuSections = [
    { id: 'home', name: 'Home', icon: 'Home' },
    { id: 'carta-del-giorno', name: 'Tageskarte', icon: 'FileText' },
    { id: 'antipasti', name: 'Vorspeisen', icon: 'Coffee' },
    { id: 'pizza', name: 'Pizza', icon: 'Pizza' },
    { id: 'pasta', name: 'Pasta', icon: 'Utensils' },
    { id: 'dolci', name: 'Nachspeise', icon: 'Cake' },
    { id: 'vino', name: 'Wein des Tages', icon: 'Wine' },
    { id: 'aperitifs', name: 'Aperitifs', icon: 'Wine' }, 
    { id: 'emporio', name: 'Feinkost', icon: 'ShoppingBag' },
    { id: 'eventi', name: 'Veranstaltungen', icon: 'Calendar' },
    { id: 'kontakt', name: 'Kontakt', icon: 'Phone' },
  ];


  const renderHomeContent = () => (
    <div className="text-center text-[#F4E1C1]">
      <div className="space-y-6">
        <p className="text-2xl font-semibold">Im Gries 16 - 86919 Utting</p>
        <div className="space-y-4">
          <p className="text-2xl font-medium underline text-black">Öffnungszeiten:</p>
          <div className="text-xl">
            <p className="font-medium">Von Dienstag bis Samstag</p>
            <p>12:00 Uhr bis 14:00 Uhr</p>
            <p>17:30 Uhr bis 1:00 Uhr</p>
          </div>
          <div className="text-xl">
            <p className="font-medium text-black">Sonntag</p>
            <p>12:00 Uhr bis 23:00 Uhr</p>
          </div>
          <p className="text-2xl font-medium text-black">Montag Ruhetag</p>
        </div>
        <p className="text-xl">
          Telefon:{' '}
          <a
            href="tel:08806959065"
            className="font-medium text-[#F4E1C1] hover:underline"
          >
            08806/959065
          </a>
        </p>
        <p className="text-xl mt-8 italic">
          Genießen Sie unsere erlesenen Speisen und erleben Sie<br />
          die Gastfreundschaft unseres Hauses.
        </p>
      </div>
    </div>
  );

  const renderMenuContent = () => {
    switch (activeSection) {
      case 'home':
        return renderHomeContent();
      case 'kontakt':
        return renderHomeContent();
      case 'carta-del-giorno':
        return <Tageskarte />;
      case 'antipasti':
        return <Vorspeisen />;
      case 'pizza':
        return <Pizza />;
      case 'pasta':
        return <Pasta />;
      case 'dolci':
        return <Dolci />;
      case 'vino':
        return <Vino />;
      case 'aperitifs':
        return <Aperitifs />; 
      case 'emporio':
        return <Feinkost />;
      case 'eventi':
        return <Eventi />;
      default:
        return (
          <p className="text-[#F4E1C1]">
            Seleziona una sezione del menu per vedere i dettagli.
          </p>
        );
    }
  };

  const handleMenuClick = (sectionId) => {
    setActiveSection(sectionId);

    if (window.innerWidth <= 768) {
      if (sectionId === 'home' && headerRef.current) {
        
        headerRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (contentRef.current) {
        
        contentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#D3BFA0]">
      <header
        className="bg-[#8B4513] text-[#F4E1C1] p-4 sm:p-6 text-center"
        ref={headerRef} 
      >
        <h1
          className="text-3xl sm:text-5xl mb-1 sm:mb-2"
          style={{ fontFamily: "'Great Vibes', cursive" }}
        >
          Willkommen im
        </h1>
        <h2
          className="text-5xl sm:text-7xl mb-4 sm:mb-6"
          style={{ fontFamily: "'Great Vibes', cursive" }}
        >
          Trattoria Pinello
        </h2>
        <div className="flex justify-center mb-6">
          <img
            src={image}
            alt="Trattoria Pinello"
            className="w-66 h-66 sm:w-82 sm:h-82 object-cover rounded-full shadow-md"
          />
        </div>
      </header>

      <main className="container mx-auto flex-grow p-4">
        <div className="text-center text-black mb-6">
          <h3
            className="text-3xl sm:text-4xl font-bold"
            style={{ fontFamily: "'Playfair Display', serif" }}
          >
            Speisekarte
          </h3>
        </div>

        <div className="flex flex-col md:flex-row flex-wrap justify-center gap-2 sm:gap-3 mb-6">
          {menuSections.map((section) => {
            const IconComponent = LucideIcons[section.icon];
            return (
              <button
                key={section.id}
                onClick={() => handleMenuClick(section.id)}
                className={`flex items-center justify-center gap-2 px-3 sm:px-4 py-2 rounded-full border ${
                  activeSection === section.id
                    ? 'bg-[#8B4513] text-[#F4E1C1]'
                    : 'bg-[#F4E1C1] text-[#4A3500] hover:bg-[#E6C9A0] border-[#D3BFA0]'
                }`}
                style={{ borderWidth: '1px' }}
              >
                {IconComponent && <IconComponent size={20} />}
                <span className="text-sm sm:text-base">{section.name}</span>
              </button>
            );
          })}
        </div>

        <div
          className="bg-[#8B4513] p-4 sm:p-6 rounded-lg shadow-md"
          ref={contentRef} 
        >
          {renderMenuContent()}
        </div>
      </main>

      <footer className="bg-[#8B4513] text-black text-center py-3 sm:py-4 mt-6 text-sm sm:text-base">
        <p>&copy; 2024 Trattoria Pinello. Alle Rechte vorbehalten.</p>
      </footer>
    </div>
  );
}

export default App;
