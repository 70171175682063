import React from 'react';

function Pasta() {
  const pasta = [
    {
      nome: 'Spaghetti Arrabiata',
      descrizione: '(Tomatensoße, Knoblauch, Chilischote, Petersilie)',
      prezzoQui: 12.40,
      prezzoAsporto: 9.40,
    },
    {
      nome: 'Spaghetti Aglio Olio',
      descrizione: '(Knoblauch, Olivenöl, Chilischote, Petersilie, Kirschtomaten)',
      prezzoQui: 11.80,
      prezzoAsporto: 9.80,
    },
    {
      nome: 'Spaghetti Amatriciana',
      descrizione: '(Tomatensoße, Speck, Zwiebel, Petersilie)',
      prezzoQui: 12.40,
      prezzoAsporto: 9.40,
    },
    {
      nome: 'Spaghetti Napoli',
      descrizione: '(Tomatensoße, Basilikum)',
      prezzoQui: 11.80,
      prezzoAsporto: 9.80,
    },
    {
      nome: 'Spaghetti Quattro Formaggi',
      descrizione: '(Gorgonzola-Käse, Pecorino-Käse, Fontina-Käse, Parmesan-Käse)',
      prezzoQui: 12.90,
      prezzoAsporto: 9.90,
    },
    {
      nome: 'Spaghetti Carbonara',
      descrizione: '(Sahne, Speck, Eigelbe, schwarzer Pfeffer, Parmesan)',
      prezzoQui: 12.40,
      prezzoAsporto: 9.40,
    },
    {
      nome: 'Pasta Vegetaria',
      descrizione: '(Verschiedene Gemüse wie Zucchini, Auberginen, Paprika, Kirschtomaten, Spinat, Brokkoli)',
      prezzoQui: 14.00,
      prezzoAsporto: 11.00,
    },
    {
      nome: 'Pasta Trüffel',
      descrizione: '(Schwarzer Trüffel frisch und in Creme, Butter, Sahne)',
      prezzoQui: 21.80,
      prezzoAsporto: 18.80,
    },
  ];

  const renderDish = (dish, index) => (
    <React.Fragment key={index}>
      {index > 0 && <hr className="my-4 border-[#F4E1C1] opacity-30" />}
      <div className="p-3 bg-[#8B4513] rounded-lg text-[#F4E1C1]">
        <h4 className="text-lg font-medium">{dish.nome}</h4>
        <p className="text-sm italic mb-2">{dish.descrizione}</p>
        <div className="flex flex-col mt-2 space-y-2">
          <div className="flex justify-between">
            <span>Hier essen:</span>
            <span className="font-bold">{dish.prezzoQui.toFixed(2)} €</span>
          </div>
          {dish.prezzoAsporto !== undefined && (
            <div className="flex justify-between">
              <span>Zum Mitnehmen:</span>
              <span className="font-bold">{dish.prezzoAsporto.toFixed(2)} €</span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Unsere Pastagerichte</h3>
      {pasta.map(renderDish)}
    </div>
  );
}

export default Pasta;