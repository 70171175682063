import React from 'react';

function Eventi() {
  const eventi = [
    {
      titolo: "Weinverkostung am Mittag",
      data: "24 November 2024",
      descrizione: "Eine Mittagsveranstaltung, gewidmet der Verkostung apulischer Weine, begleitet von einem Degustationsmenü.",
      prezzo: 0.00
    },
  ];

  const renderEvento = (evento, index) => (
    <div key={index} className="mb-6 bg-[#8B4513] rounded-lg overflow-hidden p-4 text-[#F4E1C1]">
      <h4 className="text-xl font-medium mb-2">{evento.titolo}</h4>
      <p className="text-sm font-bold mb-2">{evento.data}</p>
      <p className="text-sm italic mb-2">{evento.descrizione}</p>
      <p className="text-lg">Preis: <span className="font-bold">{evento.prezzo.toFixed(2)} €</span></p>
    </div>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Eventi Speciali</h3>
      {eventi.map(renderEvento)}
    </div>
  );
}

export default Eventi;
