import React from 'react';

function Tageskarte() {
  const cartaDelGiorno = [
    {
      categoria: 'Tages Vorspeisen',
      piatti: [
        {
          nome: 'Lachstatar auf Rote-Bete-Carpaccio',
          descrizione: '(Lachs, Rote-Bete)',
          prezzoQui: 21.70,
          prezzoAsporto: 21.70,
        },
        {
          nome: 'Hamburger mit Salsiccia, Steinpilzen und Rucola',
          descrizione: '(Salsiccia, Steinpilzen, Rucola)',
          prezzoQui: 19.90,
          prezzoAsporto: 19.90,
        },
        {
          nome: 'Gebratene Steinpilze auf cremiger Polenta',
          descrizione: '(Steinpilze, Polenta)',
          prezzoQui: 17.90,
          prezzoAsporto: 17.90,
        },
      ]
    },
    {
      categoria: 'Tages Pasta',
      piatti: [
        {
          nome: 'Ravioli mit schwarzem Trüffel',
          descrizione: '(Ravioli,Schwarze Trüffel)',
          prezzoQui: 21.70,
          prezzoAsporto: 18.70,
        },
        {
          nome: 'Orecchiette mit Salsiccia und Steinpilzen',
          descrizione: '(Orecchiette, Salsiccia, Steinpilzen)',
          prezzoQui: 21.70,
          prezzoAsporto: 18.70,
        },
        {
          nome: 'Spaghetti mit Venusmuscheln und Steinpilzen',
          descrizione: '(Venusmuscheln, Steinpilzen)',
          prezzoQui: 21.70,
          prezzoAsporto: 18.70,
        },
      ]
    },
    {
      categoria: 'Tages Fleischgerichte',
      piatti: [
        {
          nome: 'Kaninchen à la Jägerart mit herzhaften Aromen',
          descrizione: '(Kaninchen, Beilage mit Gemüse oder Gemüse und Kartoffeln)',
          prezzoQui: 23.50,
          prezzoAsporto: 20.50,
        },
        {
          nome: 'T-Bone Steak',
          descrizione: '(Beilage mit Gemüse oder Gemüse und Kartoffeln)',
          prezzoQui: 29.90,
          prezzoAsporto: 27.90,
        },
        { 
          nome: 'Gemischter Fleischteller vom Grill',
          descrizione: '(Gemischter Fleischteller, Beilage mit Gemüse oder Gemüse und Kartoffeln oder Gemischter Salat)',
          prezzoQui: 28.80,
          prezzoAsporto: 25.80,
        },
      ]
    },
    {
      categoria: 'Tages Fischgerichte',
      piatti: [
        {
          nome: 'Dorade im Salzmantel',
          descrizione: '(Dorade, Beilage mit Gemüse oder Gemüse und Kartoffeln oder Gemischter Salat)',
          prezzoQui: 26.90,
          prezzoAsporto: 23.90,
        },
        {
          nome: 'Thunfisch nach provenzalischer Art',
          descrizione: '(Beilage mit Gemüse oder Gemüse und Kartoffeln)',
          prezzoQui: 29.90,
          prezzoAsporto: 26.90,
        },
        {
          nome: 'Lachs in Dijon-Senf-Sauce',
          descrizione: '(Lachs, Beilage mit Gemüse oder Gemüse und Kartoffeln)',
          prezzoQui: 27.00,
          prezzoAsporto: 24.00,
        },
        {
          nome: 'Scampi à la Buzara mit würzigen Aromen',
          descrizione: '(Beilage mit Gemüse oder Gemüse und Kartoffeln)',
          prezzoQui: 29.50,
          prezzoAsporto: 26.50,
        },
        {
          nome: 'Muscheln',
          descrizione: '(Tomatensauce oder Weißweinsauce oder Grüne PfefferSauce)',
          prezzoQui: 21.10,
          prezzoAsporto: 18.10,
        },
      ]
    },
    {
      categoria: 'Tages Pizza',
      piatti: [
        {
          nome: 'Tages Pizza Taleggio',
          descrizione: '(Tomatensauce, Mozzarella, Taleggio, Speck, Steinpilzen)',
          prezzoQui: 17.60,
          prezzoAsporto: 14.60,
        },
        {
          nome: 'Tages Pizza Calzone',
          descrizione: '(Tomatensauce, Mozzarella, Schinken, Ricotta und Spinat)',
          prezzoQui: 18.00,
          prezzoAsporto: 18.00,
        },
        {
          nome: 'Tages Pizza Carne Salata',
          descrizione: '(Tomatensauce, Mozzarella, Gesalzenes Fleisch, Rucola und schwarzer Trüffel)',
          prezzoQui: 19.50,
          prezzoAsporto: 19.50,
        },
      ]
    },
  ];

  const renderDish = (dish, index) => (
    <React.Fragment key={index}>
      {index > 0 && <hr className="my-4 border-[#F4E1C1] opacity-30" />}
      <div className="p-3 bg-[#8B4513] rounded-lg text-[#F4E1C1]">
        <h4 className="text-lg font-medium">{dish.nome}</h4>
        <p className="text-sm italic mb-2">{dish.descrizione}</p>
        <div className="flex flex-col mt-2 space-y-2">
          <div className="flex justify-between">
            <span>Hier essen:</span>
            <span className="font-bold">{dish.prezzoQui.toFixed(2)} €</span>
          </div>
          {dish.prezzoAsporto !== undefined && (
            <div className="flex justify-between">
              <span>Zum Mitnehmen:</span>
              <span className="font-bold">{dish.prezzoAsporto.toFixed(2)} €</span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Tageskarte</h3>
      {cartaDelGiorno.map((categoria, index) => (
        <div key={index} className="mb-6">
          <h4 className="text-lg font-medium text-black mb-3">{categoria.categoria}</h4>
          {categoria.piatti.map(renderDish)}
        </div>
      ))}
    </div>
  );
}

export default Tageskarte;
