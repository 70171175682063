import React from 'react';

function Pizza() {
  const pizza = [
    {
      nome: 'Pizza Quattro Formaggi',
      descrizione: '(Tomatensoße, Mozzarella, Gorgonzola-Käse, Pecorino-Käse, Parmesan-Käse)',
      prezzoQui: 15.90,
      prezzoAsporto: 12.90,
    },
    {
      nome: 'Pizza Diavolo',
      descrizione: '(Tomatensoße, Mozzarella, Peperoni, Salami, Kapern, Sardellen)',
      prezzoQui: 17.00,
      prezzoAsporto: 14.00,
    },
    {
      nome: 'Pizza Bella Donna',
      descrizione: '(Tomatensoße, Mozzarella, Parmaschinken, Rucola, Parmesan​)',
      prezzoQui: 18.50,
      prezzoAsporto: 15.50,
    },
    {
      nome: 'Pizza Trüffel',
      descrizione: '(Tomatensoße, Mozzarella, Schwarze Trüffelcreme, Schwarzer Trüffel Frisch)',
      prezzoQui: 24.50,
      prezzoAsporto: 21.50,
    },
    {
      nome: 'Pizza Scampi',
      descrizione: '(Tomatensoße, Mozzarella, Scampi)',
      prezzoQui: 30.00,
      prezzoAsporto: 27.00,
    },
    {
      nome: 'Pizza Capriciosa',
      descrizione: '(Tomatensoße, Mozzarella, Schinken, Salami, Oliven, Peperoni, Artischocken, Champignon-Pilze)',
      prezzoQui: 16.50,
      prezzoAsporto: 13.50,
    },
    {
      nome: 'Pizza Pane Rossa',
      descrizione: '(Tomatensoße, Mit oder ohne Knoblauch)',
      prezzoQui: 8.20,
      prezzoAsporto: 6.20,
    },
    {
      nome: 'Pizza Pane Bianca',
      descrizione: '(Rosmarin, Salz)',
      prezzoQui: 8.20,
      prezzoAsporto: 6.20,
    },
    {
      nome: 'Pizza Caprese',
      descrizione: '(Tomatensoße, Mozzarella, Frische Tomaten, Mozzarella, Basilikum)',
      prezzoQui: 16.50,
      prezzoAsporto: 13.50,
    },
    {
      nome: 'Pizza Parma',
      descrizione: '(Tomatensoße, Mozzarella, Parmaschinken)',
      prezzoQui: 17.60,
      prezzoAsporto: 14.60,
    },
    {
      nome: 'Pizza Bresaola',
      descrizione: '(Tomatensoße, Mozzarella, Bresaola (Rinderschinken))',
      prezzoQui: 17.60,
      prezzoAsporto: 14.60,
    },
    {
      nome: 'Pizza Toscana',
      descrizione: '(Tomatensoße, Mozzarella, Fenchelsalami)',
      prezzoQui: 17.00,
      prezzoAsporto: 14.00,
    },
    {
      nome: 'Pizza Pera',
      descrizione: '(Tomatensoße, Mozzarella, Gorgonzola, Birnen, Walnusskern)',
      prezzoQui: 17.60,
      prezzoAsporto: 14.60,
    },
    {
      nome: 'Pizza Calabrese',
      descrizione: '(Tomatensoße, Mozzarella, Scharfe Salami)',
      prezzoQui: 15.90,
      prezzoAsporto: 13.90,
    },
    {
      nome: 'Pizza Vegetaria',
      descrizione: '(Tomatensoße, Mozzarella, Verschiedene Gemüse)',
      prezzoQui: 15.90,
      prezzoAsporto: 12.90,
    },
    {
      nome: 'Pizza Lachs',
      descrizione: '(Räucherlachs, Crème fraîche, Frühlingszwiebel)',
      prezzoQui: 17.10,
      prezzoAsporto: 14.10,
    },
    {
      nome: 'Pizza Mortadella',
      descrizione: '(Tomatensoße, Mozzarella, Mortadella)',
      prezzoQui: 15.30,
      prezzoAsporto: 12.30,
    },
    {
      nome: 'Pizza Salsiccia',
      descrizione: '(Tomatensoße, Mozzarella, Salsiccia)',
      prezzoQui: 15.30,
      prezzoAsporto: 12.30,
    },
    {
      nome: 'Pizza Tonno',
      descrizione: '(Tomatensoße, Mozzarella, Thunfisch, Zwiebel)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Pizza Margherita',
      descrizione: '(Tomatensoße, Mozzarella)',
      prezzoQui: 13.00,
      prezzoAsporto: 10.00,
    },
    {
      nome: 'Pizza Salami',
      descrizione: '(Tomatensoße, Mozzarella, Salami)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Pizza Regina',
      descrizione: '(Tomatensoße, Mozzarella, Schinken, Champignon-Pilze)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Pizza Quattro Stagioni',
      descrizione: '(Tomatensoße, Mozzarella, Schinken, Champignon-Pilze, Salami, Peperoni)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Pizza Wasily',
      descrizione: '(Tomatensoße, Mozzarella, Verschiedene Gemüse, Sardellen, Kapern, scharf)',
      prezzoQui: 15.90,
      prezzoAsporto: 12.90,
    },
    {
      nome: 'Pizza Frutti di Mare',
      descrizione: '(Tomatensoße, Mozzarella, Meeresfrüchte)',
      prezzoQui: 17.60,
      prezzoAsporto: 14.60,
    },
    {
      nome: 'Pizza Rustica',
      descrizione: '(Tomatensoße, Mozzarella, Verschiedene Sorten von Salami)',
      prezzoQui: 15.90,
      prezzoAsporto: 12.90,
    },
    {
      nome: 'Pizza Hawai',
      descrizione: '(Tomatensoße, Mozzarella, Schinken, Ananas)',
      prezzoQui: 15.90,
      prezzoAsporto: 12.90,
    },
    {
      nome: 'Pizza Salami e Prosciutto',
      descrizione: '(Tomatensoße, Mozzarella, Salami, Schinken)',
      prezzoQui: 14.70,
      prezzoAsporto: 11.70,
    },
    {
      nome: 'Pizza Romana',
      descrizione: '(Tomatensoße, Mozzarella, schwarze Oliven, Sardellen, Kapern)',
      prezzoQui: 15.90,
      prezzoAsporto: 12.90,
    },
  ];

  const renderDish = (dish, index) => (
    <React.Fragment key={index}>
      {index > 0 && <hr className="my-4 border-[#F4E1C1] opacity-30" />}
      <div className="p-3 bg-[#8B4513] rounded-lg text-[#F4E1C1]">
        <h4 className="text-lg font-medium">{dish.nome}</h4>
        <p className="text-sm italic mb-2">{dish.descrizione}</p>
        <div className="flex flex-col mt-2 space-y-2">
          <div className="flex justify-between">
            <span>Hier essen:</span>
            <span className="font-bold">{dish.prezzoQui.toFixed(2)} €</span>
          </div>
          {dish.prezzoAsporto !== undefined && (
            <div className="flex justify-between">
              <span>Zum Mitnehmen:</span>
              <span className="font-bold">{dish.prezzoAsporto.toFixed(2)} €</span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Unsere Pizzen</h3>
      {pizza.map(renderDish)}
    </div>
  );
}

export default Pizza;
